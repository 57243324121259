import { useState } from "react";
import ClaimModal from "./ClaimModal";
import { useIsMobile } from "@/hooks/useIsMobile";
import Table from "@/components/Common/Table";
import Button from "@/components/Common/Button";
import MobileTable from "./MobileTable";
import EmptyPage from "../../components/EmptyPage";
import { WalletMoney } from "@/components/Icons";

const BaseTable = ({ stakes, isLoading, setRefetch }) => {
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [stakeDetails, setStakeDetails] = useState(null);

  const toggleClaimModalHandler = () => {
    setOpenClaimModal(!openClaimModal);
  };
  const isMobile = useIsMobile(1300);

  const columns = [
    { header: "ID", key: "id", width: "60px" },
    { header: "Image", key: "image", width: "130px" },
    { header: "Type", key: "type", width: "120px" },
    { header: "Start Date", key: "startDate", width: "120px" },
    { header: "Remaining/Duration", key: "duration", width: "250px" },
    { header: "Claim Status", key: "claim", width: "160px" },
    { header: "Value", key: "value", width: "50px" },
    { header: "Withrawn", key: "withrawn", width: "140px" },
    { header: "Withrawable", key: "withrawable", width: "180px" },
    { header: "Actions", key: "actions", width: "250px" },
  ];

  const data =
    stakes &&
    stakes.length > 0 &&
    stakes.map((stake, index) => ({
      id: stake.stake_id,
      image: (
        <img
          src={`data:image/png;base64,${stake.image}`}
          alt="Stake"
          className="object-fit-cover h-[64px] d-block w-[96px] rounded-2xl"
          style={{ minWidth: "70px" }}
        />
      ),
      type: stake.stake_tile,
      startDate: stake.start_time,
      duration: `${stake.remaining_time}/${stake.contract_duration}`,
      claim: `${stake.claimed_count}/${stake.claim_count_limit}`,
      value: `${stake.stake_value_usd} $`,
      withrawn: `${stake.withdrawn_amount_usd} $`,
      withrawable: `${stake.withdrawable_final_usd} $`,
      actions: (
        <div className="flex gap-2 items-center">
          <Button
            className="me-3"
            href={
              stake.tiles_coordination
                ? `/lands?lat=${stake.tiles_coordination[0].latitude}&lng=${stake.tiles_coordination[0].longitude}`
                : "/"
            }
            size="sm"
            model="outlined"
          >
            Open in map
          </Button>
          <Button
            model="success"
            size="sm"
            onClick={() => {
              setStakeDetails(stake);
              toggleClaimModalHandler();
            }}
            disabled={!stake.claim}
            // isLoading
          >
            Claim
          </Button>
        </div>
      ),
    }));
  return (
    <>
      <div className="">
        {(stakes && stakes.length > 0) || isLoading ? (
          isMobile ? (
            <div className="grid grid-cols-12 gap-6">
              {stakes && stakes.length > 0
                ? stakes.map((stake, index) => (
                    <div
                      className="col-span-12 md:col-span-6 desktop:col-span-3"
                      key={index}
                    >
                      <MobileTable
                        stake={stake}
                        claimHandler={() => {
                          setStakeDetails(stake);
                          toggleClaimModalHandler();
                        }}
                      />
                    </div>
                  ))
                : Array.from({ length: 4 }).map((_, index) => (
                    <div
                      className="col-span-12 md:col-span-6 desktop:col-span-3"
                      key={index}
                    >
                      <MobileTable isLoading={true} />
                    </div>
                  ))}
            </div>
          ) : (
            <Table columns={columns} data={data} isLoading={isLoading} />
          )
        ) : (
          <EmptyPage
            icon={<WalletMoney />}
            title="You have not any stacking"
            info="As soon as you start stacking, its data will appear here"
            action={
              <Button model="main" size="sm" href="/lands">
                Start stacking
              </Button>
            }
          />
        )}
      </div>
      {openClaimModal && (
        <ClaimModal
          closeModalHandler={toggleClaimModalHandler}
          stakeDetails={stakeDetails}
          setStakeDetails={setStakeDetails}
          setRefetch={setRefetch}
        />
      )}
    </>
  );
};

export default BaseTable;
